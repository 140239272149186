import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  CircularProgress,
  createTheme,
} from "@mui/material";
import { DownloadOutlined } from "@mui/icons-material";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import PDFViewer from "./PDFViewer";

// Theme configuration remains the same
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f4f6f8",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

const S3PDFViewer = ({ docKey, user, day }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch presigned URL from backend
  useEffect(() => {
    const fetchPresignedUrl = async () => {
      try {
        // Fetch the presigned URL from your backend
        const response = await fetch(
          `https://backend.ekrasworks.com/api/s3pdf/presigned-url?key=${encodeURIComponent(
            docKey
          )}`
        );
        if (!response.ok) throw new Error("Failed to fetch presigned URL");

        const { presignedUrl } = await response.json();

        // Use the proxy server to fetch the PDF
        const proxyResponse = await fetch(
          `https://backend.ekrasworks.com/api/proxy-pdf?url=${encodeURIComponent(
            presignedUrl
          )}`
        );
        if (!proxyResponse.ok) throw new Error("Failed to fetch PDF");

        // Set the proxy URL as the source for the iframe
        setPresignedUrl(
          `https://backend.ekrasworks.com/api/proxy-pdf?url=${encodeURIComponent(
            presignedUrl
          )}`
        );
      } catch (error) {
        console.error("Error fetching PDF:", error);
        setError("Failed to load PDF");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPresignedUrl();
  }, [docKey]);

  const handleDocumentDownload = async () => {
    setIsDownloading(true);
    try {
      // Fetch the PDF file
      const response = await fetch(presignedUrl, { redirect: "follow" });

      // Check if the response is a PDF
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/pdf")) {
        throw new Error("The fetched resource is not a PDF");
      }

      const existingPdfBytes = await response.arrayBuffer();

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Get the first page
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      // Get page dimensions
      const { width, height } = firstPage.getSize();

      // Embed the default font
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // Create timestamp string
      const timestamp = new Date().toLocaleString();
      const watermarkText = `Downloaded on: ${timestamp} by ${user.name}`;

      // Calculate text width to position it properly
      const fontSize = 10;
      const textWidth = font.widthOfTextAtSize(watermarkText, fontSize);

      // Add watermark to each page
      pages.forEach((page) => {
        const { width, height } = page.getSize();
        page.drawText(watermarkText, {
          x: width - textWidth - 20, // 20 pixels from right margin
          y: 10, // 20 pixels from bottom
          size: fontSize,
          font: font,
          color: rgb(0.5, 0.5, 0.5), // Gray color
          opacity: 0.5, // 50% transparent
        });
      });

      // Save the modified PDF
      const modifiedPdfBytes = await pdfDoc.save();

      // Create blob from modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);

      // Create temporary anchor element and trigger download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `learning_material_${new Date().getTime()}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error processing PDF:", error);
      setError("Failed to download PDF");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 200,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {presignedUrl && !error && <PDFViewer pdfFile={presignedUrl} />}
      {day !== 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleDocumentDownload}
          startIcon={<DownloadOutlined />}
          size="large"
          fullWidth={isMobile}
          disabled={isDownloading}
        >
          {isDownloading ? "Processing..." : "Download Document"}
        </Button>
      ) : null}
      {error && !isLoading && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
    </>
  );
};

export default S3PDFViewer;
