import React from "react";
import { Typography, Container } from "@mui/material";

const NoPrinterMessage = () => {
  return (
    <Container maxWidth="sm" style={{ padding: "20px" }}>
      <Typography
        variant="h5"
        gutterBottom
        style={{ color: "#E74C3C", fontWeight: "bold" }}
      >
        No printer? No problem!
      </Typography>
      <Typography variant="body1" style={{ color: "#000" }}>
        You can download and edit this PDF, print it out, or work on any blank
        paper.{" "}
        <span style={{ fontWeight: 600 }}>
          The practice itself is what counts.
        </span>
      </Typography>
    </Container>
  );
};

export default NoPrinterMessage;
