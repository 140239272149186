export const fetchGistData = async (gistId) => {
  try {
    const response = await fetch(`https://api.github.com/gists/${gistId}`);
    if (!response.ok) throw new Error("Failed to fetch gist");
    const data = await response.json();

    let gistData = null;
    Object.entries(data.files).forEach(([filename, file]) => {
      const cleanContent = file.content
        .trim()
        .replace(/[\r\n]/g, "")
        .replace(/,\s*}/, "}");

      try {
        gistData = JSON.parse(cleanContent);
      } catch (e) {
        console.log("Parse error:", e.message);
      }
    });

    return gistData; // Return the parsed Gist data
  } catch (err) {
    console.error("Error fetching Gist:", err.message);
    return null; // Return null in case of error
  }
};
