// import { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   Paper,
//   CircularProgress,
//   Alert,
//   Divider,
// } from "@mui/material";

// const Gist = () => {
//   const [gistData, setGistData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const GIST_ID = "ba1ebeb6c85d4df887afdcd72bacfd98";

//   useEffect(() => {
//     const fetchGist = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`https://api.github.com/gists/${GIST_ID}`);
//         if (!response.ok) throw new Error("Failed to fetch gist");
//         const data = await response.json();
//         setGistData(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchGist();
//   }, []);

//   if (loading) return <CircularProgress />;
//   if (error) return <Alert severity="error">{error}</Alert>;
//   if (!gistData) return <Alert severity="info">No data found</Alert>;

//   return (
//     <Box sx={{ maxWidth: 800, margin: "0 auto", p: 3 }}>
//       <Paper elevation={3} sx={{ p: 3 }}>
//         <Typography variant="h5" gutterBottom>
//           {gistData.description || "No description available"}
//         </Typography>

//         <Divider sx={{ my: 2 }} />

//         {Object.entries(gistData.files).map(([filename, file]) => (
//           <Box key={filename} sx={{ mb: 3 }}>
//             <Typography variant="h6" color="primary">
//               {filename}
//             </Typography>
//             <Typography variant="caption" display="block" gutterBottom>
//               Language: {file.language}
//             </Typography>
//             <Paper
//               variant="outlined"
//               sx={{
//                 p: 2,
//                 backgroundColor: "#f5f5f5",
//                 overflow: "auto",
//               }}
//             >
//               <pre style={{ margin: 0 }}>
//                 <code>{file.content}</code>
//               </pre>
//             </Paper>
//           </Box>
//         ))}

//         <Box sx={{ mt: 2, pt: 2, borderTop: 1, borderColor: "divider" }}>
//           <Typography variant="body2" color="text.secondary">
//             Created: {new Date(gistData.created_at).toLocaleDateString()}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Last Updated: {new Date(gistData.updated_at).toLocaleDateString()}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Owner: {gistData.owner.login}
//           </Typography>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default Gist;

import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";

const Gist = () => {
  const [gistData, setGistData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const GIST_ID = "ba1ebeb6c85d4df887afdcd72bacfd98";

  useEffect(() => {
    const fetchGist = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://api.github.com/gists/${GIST_ID}`);
        if (!response.ok) throw new Error("Failed to fetch gist");
        const data = await response.json();

        // Debug: Log the raw content first
        Object.entries(data.files).forEach(([filename, file]) => {
          console.log("Raw content:", file.content);
          console.log("Content type:", typeof file.content);

          // Try to clean the content before parsing
          const cleanContent = file.content
            .trim() // Remove whitespace
            .replace(/[\r\n]/g, "") // Remove newlines
            .replace(/,\s*}/, "}"); // Remove trailing comma before closing brace

          console.log("Cleaned content:", cleanContent);

          try {
            const jsonContent = JSON.parse(cleanContent);
            console.log("Successfully parsed JSON:", jsonContent);
          } catch (e) {
            console.log("Parse error:", e.message);
          }
        });

        setGistData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGist();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!gistData) return <Alert severity="info">No data found</Alert>;

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", p: 3 }}>
      <Typography>Check console for JSON data</Typography>
    </Box>
  );
};

export default Gist;
