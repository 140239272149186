// import React, { useCallback, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   EmbeddedCheckoutProvider,
//   EmbeddedCheckout,
// } from "@stripe/react-stripe-js";
// import { useUser } from "../contexts/userContext";

// const stripePromise = loadStripe(
//   "pk_test_51QALeXL6zM53p2B4XzL10uFsdCKKb7V6s2gh2QiWSoEkffQ6zKJJRXB22bFObc2azhXAg5JLDFglt7snGHr3oVJZ00Xl31qjnt"
// );

// const CheckoutForm = () => {
//   const { user } = useUser();

//   const fetchClientSecret = useCallback(() => {
//     return fetch("https://backend.ekrasworks.com/create-checkout-session", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         user_id: user?.id,
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) => data.clientSecret);
//   });

//   useEffect(() => {
//     if (user?.id) fetchClientSecret();
//   }, [user?.id]);

//   const options = {
//     fetchClientSecret,
//   };

//   return (
//     <div id="checkout">
//       <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
//         <EmbeddedCheckout />
//       </EmbeddedCheckoutProvider>
//     </div>
//   );
// };

// export default CheckoutForm;

// import React, { useCallback, useEffect, useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import {
//   EmbeddedCheckoutProvider,
//   EmbeddedCheckout,
// } from "@stripe/react-stripe-js";
// import { Box } from "@mui/material";
// import { useUser } from "../contexts/userContext";

// const stripePromise = loadStripe(
//   "pk_test_51QALeXL6zM53p2B4XzL10uFsdCKKb7V6s2gh2QiWSoEkffQ6zKJJRXB22bFObc2azhXAg5JLDFglt7snGHr3oVJZ00Xl31qjnt"
// );

// const CheckoutForm = () => {
//   const { user } = useUser();
//   const [clientSecret, setClientSecret] = useState(null);

//   const fetchClientSecret = useCallback(() => {
//     return fetch("http://localhost:3001/create-checkout-session", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         user_id: user?.id,
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         setClientSecret(data.clientSecret);
//         return data.clientSecret;
//       });
//   }, [user?.id]);

//   useEffect(() => {
//     if (user?.id) fetchClientSecret();
//   }, [user?.id]);

//   const options = {
//     clientSecret,
//   };

//   return (
//     <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 2 }}>
//       <div id="checkout">
//         {clientSecret && (
//           <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
//             <EmbeddedCheckout />
//           </EmbeddedCheckoutProvider>
//         )}
//       </div>
//     </Box>
//   );
// };

// export default CheckoutForm;

import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Box } from "@mui/material";
import { useUser } from "../contexts/userContext";

const stripePromise = loadStripe(
  "pk_test_51QALeXL6zM53p2B4XzL10uFsdCKKb7V6s2gh2QiWSoEkffQ6zKJJRXB22bFObc2azhXAg5JLDFglt7snGHr3oVJZ00Xl31qjnt"
);

const CheckoutForm = () => {
  const { user } = useUser();
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Get promo code from URL if exists
  const searchParams = new URLSearchParams(window.location.search);
  const promoCode = searchParams.get("promo");

  const fetchClientSecret = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://backend.ekrasworks.com/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user?.id,
            promoCode: promoCode, // Pass promo code if exists
          }),
        }
      );
      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.id, promoCode]);

  useEffect(() => {
    if (user?.id) fetchClientSecret();
  }, [user?.id, fetchClientSecret]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        Loading checkout...
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 2 }}>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Box>
  );
};

export default CheckoutForm;
