import React, {
  useEffect,
  memo,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTimer } from "react-timer-hook";
import { PlayArrow } from "@mui/icons-material";
import { Button, Box, Grid } from "@mui/material";
import { NotificationsActive } from "@mui/icons-material";

const MyTimer = memo(
  function MyTimer({ bellTimes, bellAudio, setShowVideo, videoRef, user }) {
    const timerRef = useRef({
      bellAudio,
      bellTimes,
    });

    const [expiryTimestamp] = useState(new Date().getTime() + 1800 * 1000);
    const [showAttentionCheck, setShowAttentionCheck] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const timerConfig = useMemo(
      () => ({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => setShowVideo(true),
      }),
      [expiryTimestamp, setShowVideo]
    );

    const { seconds, minutes, isRunning, start, totalSeconds } =
      useTimer(timerConfig);

    // Memoized formatted time values
    const formattedTime = useMemo(
      () => ({
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      }),
      [minutes, seconds]
    );

    const handleAttentionCheck = useCallback(() => {
      setShowAttentionCheck(true);

      const newTimeoutId = setTimeout(() => {
        let myArray = JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        );
        myArray.push({ isAttentive: false, bellNumber: myArray.length + 1 });
        localStorage.setItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0],
          JSON.stringify(myArray)
        );
        setShowAttentionCheck(false);
      }, 5000);

      setTimeoutId(newTimeoutId);
    }, [user.preferences_link]);

    const handleAttentionResponse = useCallback(
      (isAttentive) => {
        // Clear the timeout to prevent the automatic false response
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }

        let myArray = JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        );
        myArray.push({ isAttentive, bellNumber: myArray.length + 1 });
        localStorage.setItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0],
          JSON.stringify(myArray)
        );
        setShowAttentionCheck(false);
      },
      [timeoutId, user.preferences_link]
    );

    useEffect(() => {
      let myArray =
        JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        ) || [];

      localStorage.setItem(
        "attentionResponses-" + Object.keys(user.preferences_link)[0],
        JSON.stringify(myArray)
      );
    }, [user.preferences_link]);

    // Optimized effect for bell checking
    useEffect(() => {
      const elapsedTime = 1800 - totalSeconds;
      const shouldRingBell = timerRef.current.bellTimes.includes(elapsedTime);
      const responses = JSON.parse(
        localStorage.getItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0]
        )
      );

      if (shouldRingBell && responses.length < bellTimes.length) {
        timerRef.current.bellAudio.play();
        handleAttentionCheck();
      }
    }, [
      totalSeconds,
      handleAttentionCheck,
      user.preferences_link,
      bellTimes.length,
    ]);

    // Update ref values
    useEffect(() => {
      timerRef.current = {
        bellAudio,
        bellTimes,
      };
    }, [bellAudio, bellTimes]);

    // Handle start
    const handleStart = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.contentWindow.postMessage({ method: "play" }, "*");
      }
      start();
    }, [start]);

    return (
      <Box sx={styles.mainContainer}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={styles.container}>
              <div style={styles.timerContainer}>
                <div style={styles.timer}>
                  <span>{formattedTime.minutes}</span>:
                  <span>{formattedTime.seconds}</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isRunning ? (
              <Box sx={styles.startButtonContainer}>
                <Button
                  variant="contained"
                  onClick={handleStart}
                  sx={styles.startButton}
                  startIcon={<PlayArrow />}
                >
                  Start Timer
                </Button>
              </Box>
            ) : showAttentionCheck ? (
              <Box sx={styles.attentionCheckContainer}>
                <Box sx={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    onClick={() => handleAttentionResponse(true)}
                    sx={styles.responseButton}
                    startIcon={<NotificationsActive />}
                  >
                    Click to Confirm Attention
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box sx={styles.attentivenessIndicator}>
                {JSON.parse(
                  localStorage.getItem(
                    "attentionResponses-" +
                      Object.keys(user.preferences_link)[0]
                  )
                )
                  ?.filter((response) => response.isAttentive)
                  .map((_, index) => (
                    <span key={index} style={styles.indicatorBar}>
                      |
                    </span>
                  ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.bellTimes === nextProps.bellTimes &&
      prevProps.bellAudio === nextProps.bellAudio &&
      prevProps.setShowVideo === nextProps.setShowVideo &&
      prevProps.videoRef === nextProps.videoRef &&
      prevProps.user === nextProps.user
    );
  }
);

const styles = {
  container: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Courier New', monospace",
    color: "#000",
  },
  timerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  icon: {
    fontSize: "40px",
    color: "#000",
    cursor: "pointer",
    transition: "color 0.3s ease",
  },
  attentionCheckContainer: {
    height: "120px",
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 3,
    borderRadius: 2,
    backgroundColor: "#f8f8f8",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    animation: "fadeIn 0.3s ease-in",
    margin: "0 auto",
  },
  attentionIcon: {
    fontSize: 40,
    marginBottom: 1,
  },
  questionText: {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: "#333",
    textAlign: "center",
    marginBottom: 2,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  responseButton: {
    padding: "12px 24px",
    fontSize: "1.1rem",
    textTransform: "none",
    backgroundColor: "#2196f3",
    color: "white",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#1976d2",
      transform: "scale(1.02)",
      boxShadow: "0 6px 15px rgba(33,150,243,0.3)",
    },
  },
  startButtonContainer: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  startButton: {
    height: "50px",
    padding: "10px 30px",
    fontSize: "1.1rem",
    textTransform: "none",
    borderRadius: "25px",
    backgroundColor: "#1976d2",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
  },
  attentivenessIndicator: {
    height: "120px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "10px",
    minWidth: "200px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },
  indicatorBar: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#4caf50",
    animation: "fadeIn 0.5s ease-in",
    textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      transform: "translateY(10px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  mainContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  timer: {
    fontSize: "70px",
    fontWeight: "bold",
    color: "#000",
    letterSpacing: "1px",
    lineHeight: 1,
    textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
};

export default MyTimer;
