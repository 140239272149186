import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useUser } from "../contexts/userContext";
import { supabase } from "../utils/supabase";
import { getUserByPhone } from "../controllers/users";
import Main from "./Main";
import { CircularProgress, Alert, Box, Typography } from "@mui/material";

const Home = () => {
  const [courseId, setCourseId] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState("pending");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { user, setUser } = useUser();

  const getDeviceInfo = () => {
    return {
      browser: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenSize: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
    };
  };

  const getDeviceId = () => {
    let deviceId = document.cookie.match(/deviceId=([^;]+)/);
    if (!deviceId) {
      deviceId = crypto.randomUUID();
      document.cookie = `deviceId=${deviceId};path=/;`;
    } else {
      deviceId = deviceId[1];
    }
    return deviceId;
  };

  const checkSession = async (userId) => {
    if (!userId || checkSession.isCreatingSession) return;

    try {
      checkSession.isCreatingSession = true;

      const { data: existingSessions, error } = await supabase
        .from("user_sessions")
        .select("*")
        .eq("user_id", userId);

      if (error) throw error;

      const deviceId = getDeviceId();
      const matchingSession = existingSessions?.find(
        (session) => session.device_info.deviceId === deviceId
      );

      if (matchingSession) {
        console.log("Found existing session for this device");
        setSessionDetails(matchingSession);
      } else {
        console.log("New session needed");
        await handleNewSessionVerification(userId);
      }
    } catch (error) {
      console.error("Error managing session:", error);
      setError("Error managing session. Please try again.");
    } finally {
      checkSession.isCreatingSession = false;
    }
  };

  const handleNewSessionVerification = async (userId) => {
    try {
      setError(null);
      const verificationId = crypto.randomUUID();

      await supabase.from("session_verifications").insert([
        {
          id: verificationId,
          user_id: userId,
          status: "pending",
          device_id: getDeviceId(),
          created_at: new Date().toISOString(),
        },
      ]);

      const subscription = supabase
        .channel("session_verifications")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "session_verifications",
            filter: `id=eq.${verificationId}`,
          },
          async (payload) => {
            const newStatus = payload.new.status;
            setVerificationStatus(newStatus);

            if (newStatus === "approved_remember") {
              console.log("User approved new session");
              await createNewSession(userId);
              subscription.unsubscribe();
            } else if (newStatus === "rejected") {
              setError("Session was rejected by user");
              subscription.unsubscribe();
            }
          }
        )
        .subscribe();

      setTimeout(async () => {
        if (verificationStatus === "pending") {
          await supabase
            .from("session_verifications")
            .update({ status: "expired" })
            .eq("id", verificationId);

          subscription.unsubscribe();
          setError("Verification timeout: Please try again");
        }
      }, 5 * 60 * 1000);
    } catch (error) {
      console.error("Error in session verification:", error);
      setError("Verification failed: Please try again");
    }
  };

  const createNewSession = async (userId) => {
    const deviceInfo = {
      ...getDeviceInfo(),
      deviceId: getDeviceId(),
    };

    try {
      const { data, error } = await supabase
        .from("user_sessions")
        .insert([
          {
            user_id: userId,
            device_info: deviceInfo,
            start_time: new Date().toISOString(),
            is_active: true,
          },
        ])
        .select();

      if (error) throw error;

      setSessionDetails(data[0]);
      console.log("New session created:", data[0]);
    } catch (error) {
      console.error("Error creating session:", error);
      setError("Failed to create new session");
    }
  };

  useEffect(() => {
    const verifyAndFetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const params = queryString.parse(location.search);
        if (params.id) {
          const columnName = "preferences_link";
          const { data, error } = await supabase
            .from("users_onboard")
            .select(`id, whatsapp_phone_no, ${columnName}`);

          if (error) throw error;

          if (data?.length > 0) {
            const row = data.find(
              (row) => row[columnName] && row[columnName][params.id]
            );
            if (row) {
              const userData = await getUserByPhone(row[columnName][params.id]);
              setUser(userData);
              await checkSession(userData.id);
            } else {
              setError("Invalid verification link");
            }
          }
        }
        if (params.course_id) setCourseId(params.course_id);
      } catch (err) {
        setError("Failed to verify user");
      } finally {
        setIsLoading(false);
      }
    };

    verifyAndFetchData();
  }, [location, setUser]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress size={40} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Verifying your session...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        p={2}
      >
        <Alert severity="error" sx={{ maxWidth: 400 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  if (verificationStatus === "pending" && !sessionDetails) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={2}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          Waiting for session verification... Please check your WhatsApp for
          approval
        </Typography>
      </Box>
    );
  }

  return (
    <div>{sessionDetails && <Main user={user} courseId={courseId} />}</div>
  );
};

export default Home;
