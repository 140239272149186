import React, { useEffect, useState } from "react";
import { supabase } from "../utils/supabase";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Paper,
} from "@mui/material";

const AssessmentManager = () => {
  const [code, setCode] = useState("");
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    fetchCodes();
  }, []);

  const fetchCodes = async () => {
    const { data, error } = await supabase
      .from("assessment_codes")
      .select("id, assessment_code, is_active, assessments(name)")
      .order("id", { ascending: true });

    if (error) console.error("Error fetching data:", error);
    else setCodes(data);
  };

  const handleAddCode = async () => {
    if (!code) return;
    const { error } = await supabase
      .from("assessment_codes")
      .insert([{ assessment_code: code }]);
    if (error) console.error("Error inserting code:", error);
    else {
      setCode("");
      fetchCodes();
    }
  };

  const toggleActive = async (id, isActive) => {
    const { error } = await supabase
      .from("assessment_codes")
      .update({ is_active: !isActive })
      .eq("id", id);
    if (error) console.error("Error updating status:", error);
    else fetchCodes();
  };

  return (
    <div>
      <h2>Assessment Manager</h2>

      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          label="New Assessment Code"
          variant="outlined"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button variant="contained" onClick={handleAddCode}>
          Add Code
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Assessment Code</TableCell>
              <TableCell>Assessment Name</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codes.map(({ id, assessment_code, is_active, assessments }) => (
              <TableRow key={id}>
                <TableCell>{assessment_code}</TableCell>
                <TableCell>
                  {assessments ? assessments.name : "Unknown"}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={is_active}
                    onChange={() => toggleActive(id, is_active)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AssessmentManager;
