import React, { useState, useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { supabase } from "../utils/supabase";
import dayjs from "dayjs";

const Return = () => {
  const [customerEmail, setCustomerEmail] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    const userId = urlParams.get("user_id");

    setSessionId(sessionId);
    setUserId(userId);
  }, []);

  useEffect(() => {
    const addChallengeAndCheckStatus = async () => {
      try {
        // First check if challenge already exists
        const { data: existingChallenge } = await supabase
          .from("challenges")
          .select("id")
          .eq("user_id", userId)
          .eq("course_id", 1)
          .single();

        // Only insert if no challenge exists
        if (!existingChallenge) {
          const { data, error } = await supabase.from("challenges").insert([
            {
              user_id: userId,
              course_id: 1,
              start_date: dayjs().format("YYYY-MM-DD"),
            },
          ]);

          if (error) throw error;
        }

        // Check session status
        const response = await fetch(
          `https://backend.ekrasworks.com/session-status?session_id=${sessionId}`
        );
        const dataJson = await response.json();
        setCustomerEmail(dataJson.customer_email);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (sessionId && userId) {
      addChallengeAndCheckStatus();
    }
  }, [sessionId, userId]); // Add dependencies

  const styles = {
    section: {
      maxWidth: "600px",
      margin: "40px auto",
      padding: "32px",
      textAlign: "center",
      backgroundColor: "#f0fdf4",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    icon: {
      fontSize: "64px",
      color: "#22c55e",
      marginBottom: "16px",
    },
    text: {
      fontSize: "16px",
      color: "#4b5563",
      marginBottom: "16px",
    },
    link: {
      color: "#2563eb",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };

  return (
    <Box component="section" id="success" sx={styles.section}>
      <CheckCircleOutline sx={styles.icon} />
      <Typography sx={styles.text}>
        We appreciate your business! A confirmation email will be sent to{" "}
        {customerEmail}. If you have any questions, please email{" "}
        <Link href="mailto:orders@example.com" sx={styles.link}>
          orders@example.com
        </Link>
        .
      </Typography>
    </Box>
  );

  return null;
};

export default Return;
