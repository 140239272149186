import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Link,
  Alert,
} from "@mui/material";
import QRCode from "react-qr-code";
import { supabase } from "../utils/supabase";

const UserLogin = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [qrValue, setQrValue] = useState("");
  const [timestampValue, setTimestampValue] = useState(null);
  const [deviceInfoJSON, setDeviceInfoJSON] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("isSessionActive")) {
      if (JSON.parse(localStorage.getItem("user")).preferences_link) {
        try {
          const preferencesDict = JSON.parse(
            localStorage.getItem("user")
          ).preferences_link;
          const preferenceKeys = Object.keys(preferencesDict);

          window.location.href =
            "https://challenge.ekrasworks.com/?id=" +
            preferenceKeys[0] +
            "&course_id=1";
        } catch (e) {
          console.error("Error parsing preferences:", e);
          return;
        }
      }
    }
  }, []);

  const getDeviceInfo = () => {
    return {
      browser: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenSize: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
    };
  };

  const generateQRData = async (email) => {
    const timestamp = new Date().getTime();
    setTimestampValue(timestamp);
    const deviceInfo = getDeviceInfo();
    setDeviceInfoJSON(deviceInfo);

    return `https://api.whatsapp.com/send/?phone=19259998038&text=Authenticate%20for%20${email}_${timestamp}`;
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (email) {
      const qrData = await generateQRData(email);
      setQrValue(qrData);
      setStep(2);
    }
  };

  const createNewSession = async (userId) => {
    try {
      const deviceInfo = {
        ...getDeviceInfo(),
        deviceId: JSON.stringify(timestampValue),
      };

      const { data, error } = await supabase
        .from("user_sessions")
        .insert([
          {
            user_id: userId,
            device_info: deviceInfo,
            start_time: new Date().toISOString(),
            is_active: true,
          },
        ])
        .select();

      if (error) throw error;

      console.log("New session created:", data[0]);
    } catch (error) {
      console.error("Error creating session:", error);
      setError("Failed to create new session");
    }
  };

  useEffect(() => {
    if (timestampValue) {
      // Setup visibility change listener
      document.addEventListener("visibilitychange", async () => {
        if (!document.hidden) {
          // When page becomes visible, check status directly
          const { data } = await supabase
            .from("session_verifications")
            .select("status")
            .eq("device_id", JSON.stringify(timestampValue))
            .single();

          if (data) {
            handleStatusChange(data.status, data);
          }
        }
      });

      const subscription = supabase
        .channel("session_verifications")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "session_verifications",
            filter: `device_id=eq.${JSON.stringify(timestampValue)}`,
          },
          (payload) => handleStatusChange(payload.new.status, payload.new)
        )
        .subscribe();

      const handleRedirect = async (userId) => {
        // Get the user's onboarding data
        const { data, error } = await supabase
          .from("users_onboard")
          .select("*")
          .eq("id", userId)
          .single();

        if (error) {
          console.error("Error fetching user onboarding data:", error);
          return;
        }

        // Extract keys from preferences_link
        if (data?.preferences_link) {
          try {
            const preferencesDict = data.preferences_link;
            const preferenceKeys = Object.keys(preferencesDict);

            window.location.href =
              "https://challenge.ekrasworks.com/?id=" +
              preferenceKeys[0] +
              "&course_id=1&redirect=" +
              timestampValue;
          } catch (e) {
            console.error("Error parsing preferences:", e);
            return;
          }
        }

        return;
      };

      const handleStatusChange = async (newStatus, payload) => {
        if (newStatus === "approved_remember") {
          await createNewSession(payload?.user_id);
          setTimestampValue(null);
          await handleRedirect(payload?.user_id);
          cleanup();
        } else if (newStatus === "rejected") {
          setError("Session was rejected by user");
          setTimestampValue(null);
          cleanup();
        }
      };

      const cleanup = () => {
        subscription.unsubscribe();
      };

      return cleanup;
    }
  }, [timestampValue]);

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        p={2}
      >
        <Alert severity="error" sx={{ maxWidth: 400 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 2, sm: 4 },
          marginTop: 4,
          maxWidth: "100%",
          mx: "auto",
        }}
      >
        {step === 1 ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mb: 4,
                fontWeight: 500,
              }}
            >
              Sign In
            </Typography>
            <Box
              component="form"
              onSubmit={handleNext}
              noValidate
              sx={{
                mt: 1,
                maxWidth: 400,
                mx: "auto",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 3,
                  py: 1.5,
                }}
              >
                Next
              </Button>
              {errorMessage && (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "error.main",
                    mb: 2,
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
              <Box sx={{ mt: 2 }}>
                <Link
                  href="/register"
                  variant="body2"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Don't have an account? Sign up
                </Link>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mb: 3,
                fontWeight: 500,
              }}
            >
              Scan QR Code
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 4,
                color: "text.secondary",
                maxWidth: 400,
                mx: "auto",
              }}
            >
              Scan or click the QR code to authenticate via WhatsApp. A
              pre-formatted message will appear - simply press Send to complete
              verification.
            </Typography>
            <Typography
              sx={{
                color: "error.main",
                fontWeight: 500,
                mb: 3,
                animation: "pulse 2s infinite",
                "@keyframes pulse": {
                  "0%, 100%": { opacity: 1 },
                  "50%": { opacity: 0.5 },
                },
              }}
            >
              DO NOT CLOSE THIS TAB
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                cursor: "pointer",
                "& > *": {
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                },
              }}
              onClick={() => {
                window.open(qrValue, "_blank", "noopener");
              }}
            >
              <QRCode value={qrValue} size={256} />
            </Box>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default UserLogin;
