import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DiscountCodeManager from "./DiscountCodeManager";
import AffiliateBucketManager from "./AffiliateBucketManager";
import UserReferralManager from "./UserReferralManager";
import AssessmentManager from "./AssessmentManager";

const DiscountManager = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Discount Codes" />
          <Tab label="Affiliate Buckets" />
          <Tab label="User Referrals" />
          <Tab label="Assessment Codes" />
        </Tabs>

        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && <DiscountCodeManager />}
          {activeTab === 1 && <AffiliateBucketManager />}
          {activeTab === 2 && <UserReferralManager />}
          {activeTab === 3 && <AssessmentManager />}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DiscountManager;
