import React, { useCallback, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useUser } from "../contexts/userContext";

const stripePromise = loadStripe(
  "pk_test_51QALeXL6zM53p2B4XzL10uFsdCKKb7V6s2gh2QiWSoEkffQ6zKJJRXB22bFObc2azhXAg5JLDFglt7snGHr3oVJZ00Xl31qjnt"
);

const CheckoutForm = () => {
  const { user } = useUser();

  const fetchClientSecret = useCallback(() => {
    return fetch("https://backend.ekrasworks.com/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user?.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  });

  useEffect(() => {
    if (user?.id) fetchClientSecret();
  }, [user?.id]);

  const options = {
    fetchClientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
