import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";

const Stripe = () => {
  const stripePromise = loadStripe(
    "pk_test_51QALeXL6zM53p2B4XzL10uFsdCKKb7V6s2gh2QiWSoEkffQ6zKJJRXB22bFObc2azhXAg5JLDFglt7snGHr3oVJZ00Xl31qjnt"
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Stripe;
