// import React from "react";
// import { Box, Typography } from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
// import dayjs from "dayjs";

// const StreakCalendar = ({ completedDates = [], startDate }) => {
//   // Calculate days elapsed since start
//   const start = dayjs(startDate);
//   const today = dayjs();
//   const daysElapsed = today.diff(start, "day") + 1;
//   const daysArray = Array.from({ length: Math.min(daysElapsed, 21) }, (_, i) =>
//     start.add(i, "day")
//   );

//   return (
//     <Box sx={{ width: "100%", mt: 2 }}>
//       <Typography
//         variant="subtitle1"
//         sx={{ mb: 2, color: "text.secondary", textAlign: "center" }}
//       >
//         21-Day Learning Challenge Progress
//       </Typography>
//       <Box
//         sx={{
//           display: "grid",
//           gridTemplateColumns: "repeat(7, 1fr)",
//           gap: 1,
//           p: 2,
//           bgcolor: "background.paper",
//           borderRadius: 2,
//           boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
//         }}
//       >
//         {daysArray.map((date, index) => {
//           const isCompleted = completedDates.some(
//             (completedDate) =>
//               dayjs(completedDate).format("YYYY-MM-DD") ===
//               date.format("YYYY-MM-DD")
//           );

//           return (
//             <Box
//               key={date.format("YYYY-MM-DD")}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 p: 1,
//                 borderRadius: 1,
//                 bgcolor: "rgba(0,0,0,0.02)",
//                 position: "relative",
//               }}
//             >
//               <Typography
//                 variant="caption"
//                 sx={{ mb: 0.5, color: "text.secondary" }}
//               >
//                 {date.format("DD")}
//               </Typography>
//               {isCompleted ? (
//                 <CheckCircleIcon
//                   sx={{
//                     color: "success.main",
//                     fontSize: "1.5rem",
//                     opacity: 0.8,
//                   }}
//                 />
//               ) : (
//                 <CancelIcon
//                   sx={{
//                     color: "error.main",
//                     fontSize: "1.5rem",
//                     opacity: 0.3,
//                   }}
//                 />
//               )}
//               <Typography
//                 variant="caption"
//                 sx={{
//                   mt: 0.5,
//                   fontSize: "0.6rem",
//                   color: "text.secondary",
//                 }}
//               >
//                 {date.format("MMM")}
//               </Typography>
//             </Box>
//           );
//         })}
//       </Box>
//     </Box>
//   );
// };

// export default StreakCalendar;

import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const StreakCalendar = ({ completedDates = [], startDate }) => {
  const start = dayjs(startDate);
  const today = dayjs();

  // Find the last completed date or today, whichever is later
  const lastCompletedDate =
    completedDates.length > 0
      ? dayjs(Math.max(...completedDates.map((date) => dayjs(date).valueOf())))
      : null;
  const endDate =
    lastCompletedDate && lastCompletedDate.isAfter(today)
      ? lastCompletedDate
      : today;

  // Calculate total days needed to show (either 21 days from start or up to the last completion)
  const totalDays = Math.max(21, endDate.diff(start, "day") + 1);

  // Generate array of all days from start to the calculated end
  const daysArray = Array.from({ length: totalDays }, (_, i) =>
    start.add(i, "day")
  );

  // Count completed days
  const completedCount = completedDates.length;

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ mr: 1, color: "primary.main" }} />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Streak Calendar
          </Typography>
        </Box>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
          Track your 21-day learning journey
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: 1,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {daysArray.map((date, index) => {
          const isCompleted = completedDates.some(
            (completedDate) =>
              dayjs(completedDate).format("YYYY-MM-DD") ===
              date.format("YYYY-MM-DD")
          );
          const isPastDate = date.isBefore(today, "day");
          const isToday = date.isSame(today, "day");
          const isWithin21Days = index < 21;

          return (
            <Box
              key={date.format("YYYY-MM-DD")}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 1,
                borderRadius: 1,
                bgcolor: isToday
                  ? "rgba(25, 118, 210, 0.08)"
                  : isWithin21Days
                  ? "rgba(0,0,0,0.02)"
                  : "rgba(0,0,0,0.01)",
                position: "relative",
                border: isToday ? "1px solid rgba(25, 118, 210, 0.5)" : "none",
                opacity: isWithin21Days ? 1 : 0.7,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  mb: 0.5,
                  color: isToday ? "primary.main" : "text.secondary",
                  fontWeight: isToday ? 600 : 400,
                }}
              >
                {date.format("DD")}
              </Typography>
              {isPastDate || isToday ? (
                isCompleted ? (
                  <CheckCircleIcon
                    sx={{
                      color: "success.main",
                      fontSize: "1.5rem",
                      opacity: isWithin21Days ? 0.8 : 0.5,
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "error.main",
                      fontSize: "1.5rem",
                      opacity: isWithin21Days ? 0.3 : 0.2,
                    }}
                  />
                )
              ) : (
                <Box
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    border: "1px dashed rgba(0,0,0,0.2)",
                    opacity: isWithin21Days ? 1 : 0.5,
                  }}
                />
              )}
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  fontSize: "0.6rem",
                  color: isToday ? "primary.main" : "text.secondary",
                  fontWeight: isToday ? 600 : 400,
                }}
              >
                {date.format("MMM")}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StreakCalendar;
