import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Link,
} from "@mui/material";
import QRCode from "react-qr-code";
import { createUser } from "../controllers/users";
import SurveyModal from "./SurveyModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const UserRegistrationPage = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({ name: "", email: "" });
  const [qrValue, setQrValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [surveyOpen, setSurveyOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.name && userData.email) {
      createUser(userData.name, userData.email)
        .then((res) => {
          if (res.error) {
            setErrorMessage({
              type: "error",
              message:
                res.error.message ===
                'duplicate key value violates unique constraint "users_onboard_email_key"'
                  ? "Email already exists"
                  : "An error occurred.",
            });
          } else {
            setQrValue(
              "https://api.whatsapp.com/send/?phone=19259998038&text=Please%20press%20send%20for%20" +
                res.data[0].id +
                "x" +
                new Date().getTime()
            );
            setStep(2);
          }
        })
        .catch((err) => {
          setErrorMessage({
            type: "error",
            message: err.message || "An error occurred.",
          });
        });
    }
  };

  console.log("QR CODE VALUE: ", qrValue);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        {step === 1 ? (
          <>
            <Typography variant="h4" gutterBottom>
              User Registration
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Button
                onClick={() => setSurveyOpen(true)}
                variant="outlined"
                startIcon={
                  <WhatsAppIcon
                    sx={{ color: "#25D366", width: "2rem", height: "2rem" }}
                  />
                }
                sx={{
                  py: 1,
                  fontSize: "1rem",
                  fontWeight: "500",
                  textTransform: "none",
                  color: "primary.main",
                  borderColor: "primary.main",
                  animation: "pulse 2s infinite",
                  "@keyframes pulse": {
                    "0%": { transform: "scale(1)" },
                    "50%": { transform: "scale(1.02)" },
                    "100%": { transform: "scale(1)" },
                  },
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.04)",
                    borderColor: "primary.dark",
                    animation: "none",
                  },
                  width: "100%",
                  "@media (max-width: 600px)": {
                    lineHeight: 1.2,
                  },
                }}
              >
                Take our 4-question productivity survey
              </Button>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  mt: 0.5,
                  textAlign: "left", // Align text to the left for continuity
                  pl: 1, // Add padding to align with the button's start icon
                  lineHeight: 1.4, // Default line height
                  "@media (max-width: 600px)": {
                    lineHeight: 1.2, // Reduce line spacing on smaller screens
                  },
                }}
              >
                or register directly by providing the details below
              </Typography>
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                autoComplete="name"
                autoFocus
                value={userData.name}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={userData.email}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Next
              </Button>
              {errorMessage && (
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {errorMessage.message}
                </Typography>
              )}
              <Box sx={{ textAlign: "center", mt: 1 }}>
                <Link
                  href="/signin"
                  variant="body2"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    fontSize: "18px",
                  }}
                >
                  Already have an account? Sign in
                </Link>
              </Box>
            </Box>

            <SurveyModal
              open={surveyOpen}
              onClose={() => setSurveyOpen(false)}
            />
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Link Your Phone
            </Typography>
            <Typography variant="body1" paragraph>
              Scan this QR code with your phone or click on it to link your
              WhatsApp account
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = qrValue;
              }}
            >
              <QRCode value={qrValue} size={256} />
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default UserRegistrationPage;
