import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supabase";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const AffiliateBucketManager = () => {
  const [bucketFormData, setBucketFormData] = useState({
    name: "",
    discount_percentage: "",
  });
  const [affiliateCodeFormData, setAffiliateCodeFormData] = useState({
    code: "",
    tier_id: "",
    affiliate_id: "",
    valid_from: dayjs(),
    valid_until: dayjs().add(1, "year"),
    max_count: null, // Add max_count
    has_max_count: false, // Add a flag to toggle max_count
  });
  const [loading, setLoading] = useState(false);
  const [affiliateBuckets, setAffiliateBuckets] = useState([]);
  const [affiliateCodes, setAffiliateCodes] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [editMode, setEditMode] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);

  useEffect(() => {
    fetchAffiliateBuckets();
    fetchAffiliateCodes();
  }, []);

  const fetchAffiliateBuckets = async () => {
    const { data, error } = await supabase
      .from("discount_tiers")
      .select("*")
      .order("created_at", { ascending: false });
    if (data) setAffiliateBuckets(data);
  };

  const fetchAffiliateCodes = async () => {
    const { data, error } = await supabase
      .from("affiliate_codes")
      .select(
        `
        *,
        discount_tiers (
          name,
          discount_percentage
        )
      `
      )
      .order("created_at", { ascending: false });
    if (data) setAffiliateCodes(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error } = await supabase.from("discount_tiers").insert([
        {
          name: bucketFormData.name,
          discount_percentage: parseFloat(bucketFormData.discount_percentage),
          is_active: true,
        },
      ]);
      if (error) throw error;
      setAlert({
        open: true,
        message: "Affiliate bucket created successfully!",
        severity: "success",
      });
      resetForm();
      fetchAffiliateBuckets();
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error } = await supabase
        .from("discount_tiers")
        .update({
          name: bucketFormData.name,
          discount_percentage: parseFloat(bucketFormData.discount_percentage),
        })
        .eq("id", selectedBucket.id);
      if (error) throw error;
      setAlert({
        open: true,
        message: "Affiliate bucket updated successfully!",
        severity: "success",
      });
      resetForm();
      fetchAffiliateBuckets();
      setEditMode(false);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivate = async (id) => {
    try {
      const { error } = await supabase
        .from("discount_tiers")
        .update({ is_active: false })
        .eq("id", id);
      if (error) throw error;
      setAlert({
        open: true,
        message: "Affiliate bucket deactivated successfully!",
        severity: "success",
      });
      fetchAffiliateBuckets();
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  // const handleAffiliateCodeSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const { error } = await supabase.from("affiliate_codes").insert([
  //       {
  //         code: affiliateCodeFormData.code.toUpperCase(),
  //         tier_id: affiliateCodeFormData.tier_id,
  //         valid_from: affiliateCodeFormData.valid_from.toISOString(),
  //         valid_until: affiliateCodeFormData.valid_until.toISOString(),
  //         is_active: true,
  //       },
  //     ]);
  //     if (error) throw error;
  //     setAlert({
  //       open: true,
  //       message: "Affiliate code created successfully!",
  //       severity: "success",
  //     });
  //     resetAffiliateCodeForm();
  //     fetchAffiliateCodes();
  //   } catch (error) {
  //     setAlert({
  //       open: true,
  //       message: error.message,
  //       severity: "error",
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAffiliateCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const dataToSend = {
        code: affiliateCodeFormData.code.toUpperCase(),
        tier_id: affiliateCodeFormData.tier_id,
        valid_from: affiliateCodeFormData.valid_from.toISOString(),
        valid_until: affiliateCodeFormData.valid_until.toISOString(),
        is_active: true,
      };

      if (affiliateCodeFormData.has_max_count) {
        dataToSend.max_count = affiliateCodeFormData.max_count;
      }

      const { error } = await supabase
        .from("affiliate_codes")
        .insert([dataToSend]);
      if (error) throw error;
      setAlert({
        open: true,
        message: "Affiliate code created successfully!",
        severity: "success",
      });
      resetAffiliateCodeForm();
      fetchAffiliateCodes();
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setBucketFormData({
      name: "",
      discount_percentage: "",
    });
    setSelectedBucket(null);
  };

  // const resetAffiliateCodeForm = () => {
  //   setAffiliateCodeFormData({
  //     code: "",
  //     tier_id: "",
  //     valid_from: dayjs(),
  //     valid_until: dayjs().add(1, "year"),
  //   });
  // };

  const resetAffiliateCodeForm = () => {
    setAffiliateCodeFormData({
      code: "",
      tier_id: "",
      valid_from: dayjs(),
      valid_until: dayjs().add(1, "year"),
      max_count: null,
      has_max_count: false,
    });
  };

  const columns = [
    { field: "name", headerName: "Bucket Name", width: 200 },
    {
      field: "discount_percentage",
      headerName: "Discount %",
      width: 130,
      renderCell: (params) => `${params.value}%`,
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Typography color={params.value ? "success.main" : "error.main"}>
          {params.value ? "Active" : "Inactive"}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <Box>
          {params.row.is_active && (
            <>
              <IconButton
                onClick={() => {
                  setSelectedBucket(params.row);
                  setBucketFormData({
                    name: params.row.name,
                    discount_percentage: params.row.discount_percentage,
                  });
                  setEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeactivate(params.row.id)}
                color="error"
              >
                <BlockIcon />
              </IconButton>
            </>
          )}
        </Box>
      ),
    },
  ];

  // const affiliateCodeColumns = [
  //   { field: "code", headerName: "Code", width: 130 },
  //   {
  //     field: "discount_tiers",
  //     headerName: "Tier",
  //     width: 200,
  //     renderCell: (params) =>
  //       `${params.value.name} (${params.value.discount_percentage}%)`,
  //   },
  //   {
  //     field: "valid_from",
  //     headerName: "Valid From",
  //     width: 200,
  //     renderCell: (params) => dayjs(params.value).format("MMM D, YYYY h:mm A"),
  //   },
  //   {
  //     field: "valid_until",
  //     headerName: "Valid Until",
  //     width: 200,
  //     renderCell: (params) => dayjs(params.value).format("MMM D, YYYY h:mm A"),
  //   },
  //   {
  //     field: "is_active",
  //     headerName: "Status",
  //     width: 130,
  //     renderCell: (params) => (
  //       <Typography color={params.value ? "success.main" : "error.main"}>
  //         {params.value ? "Active" : "Inactive"}
  //       </Typography>
  //     ),
  //   },
  // ];

  const affiliateCodeColumns = [
    { field: "code", headerName: "Code", width: 130 },
    {
      field: "discount_tiers",
      headerName: "Tier",
      width: 200,
      renderCell: (params) =>
        `${params.value.name} (${params.value.discount_percentage}%)`,
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      width: 200,
      renderCell: (params) => dayjs(params.value).format("MMM D, YYYY h:mm A"),
    },
    {
      field: "valid_until",
      headerName: "Valid Until",
      width: 200,
      renderCell: (params) => dayjs(params.value).format("MMM D, YYYY h:mm A"),
    },
    {
      field: "max_count",
      headerName: "Max Count",
      width: 130,
      renderCell: (params) => (params.value ? params.value : "No Limit"),
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Typography color={params.value ? "success.main" : "error.main"}>
          {params.value ? "Active" : "Inactive"}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          {editMode ? "Edit Affiliate Bucket" : "Create Affiliate Bucket"}
        </Typography>
        <Box component="form" onSubmit={editMode ? handleEdit : handleSubmit}>
          <TextField
            fullWidth
            label="Bucket Name"
            value={bucketFormData.name}
            onChange={(e) =>
              setBucketFormData({ ...bucketFormData, name: e.target.value })
            }
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Discount Percentage"
            type="number"
            value={bucketFormData.discount_percentage}
            onChange={(e) =>
              setBucketFormData({
                ...bucketFormData,
                discount_percentage: e.target.value,
              })
            }
            margin="normal"
            required
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                step: "0.01",
              },
            }}
          />
          <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
            >
              {loading ? "Processing..." : editMode ? "Update" : "Create"}
            </Button>
            {editMode && (
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  setEditMode(false);
                  resetForm();
                }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      <Typography variant="h5" gutterBottom>
        Existing Affiliate Buckets
      </Typography>
      <DataGrid
        rows={affiliateBuckets}
        columns={columns}
        pageSize={5}
        autoHeight
        disableSelectionOnClick
        sx={{
          backgroundColor: "background.paper",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />

      <Paper elevation={3} sx={{ p: 3, mb: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Create Affiliate Code
        </Typography>
        {/* <Box component="form" onSubmit={handleAffiliateCodeSubmit}>
          <TextField
            fullWidth
            label="Affiliate Code"
            value={affiliateCodeFormData.code}
            onChange={(e) =>
              setAffiliateCodeFormData({
                ...affiliateCodeFormData,
                code: e.target.value,
              })
            }
            margin="normal"
            required
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Tier</InputLabel>
            <Select
              value={affiliateCodeFormData.tier_id}
              onChange={(e) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  tier_id: e.target.value,
                })
              }
              label="Select Tier"
              required
            >
              {affiliateBuckets
                .filter((bucket) => bucket.is_active)
                .map((bucket) => (
                  <MenuItem key={bucket.id} value={bucket.id}>
                    {bucket.name} - {bucket.discount_percentage}%
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <DateTimePicker
              label="Valid From"
              value={affiliateCodeFormData.valid_from}
              onChange={(newValue) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  valid_from: newValue,
                })
              }
              sx={{ flex: 1 }}
            />
            <DateTimePicker
              label="Valid Until"
              value={affiliateCodeFormData.valid_until}
              onChange={(newValue) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  valid_until: newValue,
                })
              }
              sx={{ flex: 1 }}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{ mt: 3 }}
          >
            Create Affiliate Code
          </Button>
        </Box> */}
        <Box component="form" onSubmit={handleAffiliateCodeSubmit}>
          <TextField
            fullWidth
            label="Affiliate Code"
            value={affiliateCodeFormData.code}
            onChange={(e) =>
              setAffiliateCodeFormData({
                ...affiliateCodeFormData,
                code: e.target.value,
              })
            }
            margin="normal"
            required
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Tier</InputLabel>
            <Select
              value={affiliateCodeFormData.tier_id}
              onChange={(e) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  tier_id: e.target.value,
                })
              }
              label="Select Tier"
              required
            >
              {affiliateBuckets
                .filter((bucket) => bucket.is_active)
                .map((bucket) => (
                  <MenuItem key={bucket.id} value={bucket.id}>
                    {bucket.name} - {bucket.discount_percentage}%
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <DateTimePicker
              label="Valid From"
              value={affiliateCodeFormData.valid_from}
              onChange={(newValue) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  valid_from: newValue,
                })
              }
              sx={{ flex: 1 }}
            />
            <DateTimePicker
              label="Valid Until"
              value={affiliateCodeFormData.valid_until}
              onChange={(newValue) =>
                setAffiliateCodeFormData({
                  ...affiliateCodeFormData,
                  valid_until: newValue,
                })
              }
              sx={{ flex: 1 }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={affiliateCodeFormData.has_max_count}
                  onChange={(e) =>
                    setAffiliateCodeFormData({
                      ...affiliateCodeFormData,
                      has_max_count: e.target.checked,
                      max_count: e.target.checked
                        ? affiliateCodeFormData.max_count
                        : null,
                    })
                  }
                />
              }
              label="Set Max Count"
            />
            {affiliateCodeFormData.has_max_count && (
              <TextField
                fullWidth
                label="Max Count"
                type="number"
                value={affiliateCodeFormData.max_count || ""}
                onChange={(e) =>
                  setAffiliateCodeFormData({
                    ...affiliateCodeFormData,
                    max_count: parseInt(e.target.value, 10),
                  })
                }
                margin="normal"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            )}
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{ mt: 3 }}
          >
            Create Affiliate Code
          </Button>
        </Box>
      </Paper>

      <Typography variant="h5" gutterBottom>
        Affiliate Codes
      </Typography>
      <DataGrid
        rows={affiliateCodes}
        columns={affiliateCodeColumns}
        pageSize={5}
        autoHeight
        disableSelectionOnClick
        sx={{
          backgroundColor: "background.paper",
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
      />

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          severity={alert.severity}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AffiliateBucketManager;
