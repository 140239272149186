import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Avatar,
  LinearProgress,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Snackbar,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  DownloadOutlined,
  PlayCircleOutline,
  TimelineOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import PDFDay1 from "../assets/pdf_day_1.pdf";
import { supabase } from "../utils/supabase";
import axios from "axios";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import dayjs from "dayjs";
import StreakCalendar from "../components/StreakCalendar";
import AttentionScoreGrid from "../components/AttentionScoreGrid";
import MyTimer from "../components/MyTimer";

// Theme configuration remains the same
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f4f6f8",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

const tabs = [
  { label: "Learning Material", icon: <DownloadOutlined /> },
  { label: "Video Lesson", icon: <PlayCircleOutline /> },
  { label: "Streak Stats", icon: <TimelineOutlined /> },
];

const Main = ({ user, courseId, initialDay }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [streakStats, setStreakStats] = useState({ streak: 0 });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);
  const [showShareSnackbar, setShowShareSnackbar] = useState(false);
  const [challengeData, setChallengeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDay, setSelectedDay] = useState(initialDay || 1);

  const MotionCard = motion(Card);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const videoRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [bellAudio] = useState(new Audio("/bell-ring.mp3"));
  const [bellTimes] = useState(() => {
    const times = new Set();
    while (times.size < 5) {
      const time = Math.floor(300 + Math.random() * 1200);
      times.add(time);
    }
    return Array.from(times).sort((a, b) => a - b);
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("isSessionActive");
    window.location.href = "https://challenge.ekrasworks.com/signin";
    handleClose();
  };

  useEffect(() => {
    const fetchChallengeData = async () => {
      if (user?.id && courseId) {
        try {
          const { data: challenge } = await supabase
            .from("challenges")
            .select("*")
            .eq("user_id", user.id)
            .eq("course_id", courseId)
            .single();

          if (challenge) {
            const { data: progressData } = await supabase
              .from("daily_progress")
              .select("*")
              .eq("challenge_id", challenge.id)
              .eq("is_completed", true);

            const { data: attention } = await supabase
              .from("attention_scores")
              .select("*")
              .eq("challenge_id", challenge.id);

            const progress = progressData || [];
            const completedDates = progress.map((p) => p.date);
            const sortedCompletedDates = [...completedDates].sort(
              (a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()
            );

            const maxAvailableDay = Math.min(completedDates.length + 1, 21);

            // Only update selectedDay if initialDay is invalid
            if (!initialDay || initialDay > maxAvailableDay) {
              setSelectedDay(maxAvailableDay);
            }

            const calculateStreak = (dates) => {
              if (!dates.length) return 0;

              // Sort dates in ascending order
              const sortedDates = dates
                .map((date) => dayjs(date))
                .sort((a, b) => a.valueOf() - b.valueOf());

              const today = dayjs();
              const lastDate = sortedDates[sortedDates.length - 1];

              // If last completion is before yesterday, streak is broken
              if (lastDate.isBefore(today.subtract(1, "day"), "day")) {
                return 0;
              }

              // Start from the most recent date and count backwards
              let streak = 1;
              let currentDate = lastDate;

              // Count consecutive days backwards from the last date
              for (let i = sortedDates.length - 2; i >= 0; i--) {
                const prevDate = sortedDates[i];

                // If there's a gap, break the streak
                if (currentDate.diff(prevDate, "days") !== 1) {
                  break;
                }

                streak++;
                currentDate = prevDate;
              }

              return streak;
            };

            const continuousStreak = calculateStreak(sortedCompletedDates);

            const attentionScores = attention.reduce((acc, curr) => {
              if (!acc[curr.date]) {
                acc[curr.date] = Array(5).fill(false);
              }
              acc[curr.date][curr.bell_number - 1] = curr.is_attentive;
              return acc;
            }, {});

            setChallengeData({
              challenge,
              completedDates,
              attentionScores,
              currentStreak: continuousStreak,
            });

            setStreakStats({ streak: continuousStreak });
          }
        } catch (error) {
          console.error("Error fetching challenge data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchChallengeData();
  }, [user?.id, courseId, initialDay]);

  const handleMarkAsComplete = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      const today = dayjs().format("YYYY-MM-DD");

      // Get attention responses from localStorage
      const attentionResponses = JSON.parse(
        localStorage.getItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0]
        )
      );

      // Validate all 5 bells are answered
      if (!attentionResponses || attentionResponses.length !== 5) {
        throw new Error(
          "Please respond to all 5 attention bells before completing the day"
        );
      }

      // Save progress
      await supabase.from("daily_progress").upsert({
        user_id: user.id,
        challenge_id: challengeData.challenge.id,
        date: today,
        is_completed: true,
      });

      // Save attention scores
      const attentionRecords = attentionResponses.map((response) => ({
        user_id: user.id,
        challenge_id: challengeData.challenge.id,
        date: today,
        bell_number: response.bellNumber,
        is_attentive: response.isAttentive,
      }));

      await supabase.from("attention_scores").upsert(attentionRecords);

      // Calculate new streak based on consecutive days only
      const newCompletedDates = [...challengeData.completedDates, today].sort();

      const calculateNewStreak = (dates) => {
        if (!dates.length) return 0;

        const sortedDates = dates
          .map((date) => dayjs(date))
          .sort((a, b) => a.valueOf() - b.valueOf());

        const today = dayjs();
        const lastDate = sortedDates[sortedDates.length - 1];

        // If last completion was before yesterday, streak is broken
        if (lastDate.isBefore(today.subtract(1, "day"), "day")) {
          return 0;
        }

        // Start counting streak from most recent date backwards
        let streak = 1;
        let currentDate = lastDate;

        for (let i = sortedDates.length - 2; i >= 0; i--) {
          const prevDate = sortedDates[i];

          // If there's a gap, break the streak count
          if (currentDate.diff(prevDate, "days") !== 1) {
            break;
          }

          streak++;
          currentDate = prevDate;
        }

        return streak;
      };

      const newStreak = calculateNewStreak(newCompletedDates);

      // Send feedback message
      await axios.post(
        "https://backend.ekrasworks.com/send-feedback-reflection",
        {
          phone: user.whatsapp_phone_no,
          newStreak: initialDay,
        }
      );

      // Update attention scores in local state
      const updatedAttentionScores = { ...challengeData.attentionScores };
      updatedAttentionScores[today] = attentionResponses.map(
        (r) => r.isAttentive
      );

      // Update challenge data state
      setChallengeData((prev) => ({
        ...prev,
        completedDates: newCompletedDates,
        currentStreak: newStreak,
        attentionScores: updatedAttentionScores,
      }));

      // Update streak stats
      setStreakStats({ streak: newStreak });

      // Clear localStorage
      localStorage.removeItem(
        "attentionResponses-" + Object.keys(user.preferences_link)[0]
      );

      setCurrentTab(2); // Switch to streak view
    } catch (error) {
      console.error("Error marking day as complete:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Document download handler remains the same
  const handleDocumentDownload = async () => {
    setIsDownloading(true);
    try {
      // Fetch the PDF file
      const response = await fetch(PDFDay1);
      const existingPdfBytes = await response.arrayBuffer();

      // Load the PDF document
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Get the first page
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      // Get page dimensions
      const { width, height } = firstPage.getSize();

      // Embed the default font
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // Create timestamp string
      const timestamp = new Date().toLocaleString();
      const watermarkText = `Downloaded on: ${timestamp} by ${user.name}`;

      // Calculate text width to position it properly
      const fontSize = 10;
      const textWidth = font.widthOfTextAtSize(watermarkText, fontSize);

      // Add watermark to each page
      pages.forEach((page) => {
        const { width, height } = page.getSize();
        page.drawText(watermarkText, {
          x: width - textWidth - 20, // 20 pixels from right margin
          y: 20, // 20 pixels from bottom
          size: fontSize,
          font: font,
          color: rgb(0.5, 0.5, 0.5), // Gray color
          opacity: 0.5, // 50% transparent
        });
      });

      // Save the modified PDF
      const modifiedPdfBytes = await pdfDoc.save();

      // Create blob from modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(blob);

      // Create temporary anchor element and trigger download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `learning_material_${new Date().getTime()}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error processing PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleShare = async () => {
    try {
      const response = await axios.post(
        "https://backend.ekrasworks.com/generate-share-link",
        {
          userId: user.id,
          challengeId: challengeData.challenge.id,
          streakData: {
            currentStreak: streakStats.streak,
            startDate: challengeData.challenge.start_date,
            completedDates: challengeData.completedDates,
          },
          attentionData: challengeData.attentionScores,
        }
      );

      const fullShareUrl = `${window.location.origin}/share/${response.data.shareId}`;
      setShareUrl(fullShareUrl);
      setShowShareSnackbar(true);
      navigator.clipboard.writeText(fullShareUrl);
    } catch (error) {
      console.error("Failed to generate share link:", error);
    }
  };

  const renderContent = () => {
    switch (currentTab) {
      case 0:
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                "& span": { color: "text.secondary", fontWeight: 400 },
              }}
            >
              Day {selectedDay} <span>of 21: Learning Material</span>
            </Typography>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingTop: {
                  xs: "100%",
                  sm: "56.25%",
                },
                overflow: "hidden",
                mb: 2,
              }}
            >
              <iframe
                src={PDFDay1}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDocumentDownload}
              startIcon={<DownloadOutlined />}
              size="large"
              fullWidth={isMobile}
              disabled={isDownloading}
            >
              {isDownloading ? "Processing..." : "Download Document"}
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                "& span": { color: "text.secondary", fontWeight: 400 },
              }}
            >
              Day {selectedDay} <span>of 21: Video Lesson</span>
            </Typography>
            <MyTimer
              setShowVideo={setShowVideo}
              // bellTimes={bellTimes}
              bellTimes={[15, 25, 35, 45, 55]}
              bellAudio={bellAudio}
              videoRef={videoRef}
              user={user}
            />
            <Box
              sx={{
                height: isMobile ? "300px" : "500px",
                width: "100%",
                mb: 2,
              }}
            >
              <iframe
                ref={videoRef}
                width="100%"
                height="100%"
                src="https://player.vimeo.com/video/516002602?api=1"
                title="Vimeo video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMarkAsComplete}
              startIcon={<PlayCircleOutline />}
              size="large"
              fullWidth={isMobile}
              // disabled={isUpdating || !showVideo}
            >
              {isUpdating ? "Updating..." : "Mark as Completed"}
            </Button>
          </>
        );
      case 2:
        if (!challengeData) return null;
        return (
          <Box sx={{ width: "100%", position: "relative" }}>
            <IconButton
              onClick={handleShare}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
              }}
            >
              <ShareIcon />
            </IconButton>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                "& span": {
                  color: "text.secondary",
                  fontWeight: 400,
                },
              }}
            >
              Day {selectedDay}
              <span> of 21: Your Learning Streak</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" fontWeight={700} color="primary">
                {challengeData.currentStreak}
              </Typography>
              <Typography variant="h6" sx={{ ml: 1 }}>
                days 🔥
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={(challengeData.currentStreak / 21) * 100}
              sx={{ height: 10, borderRadius: 5, mb: 4 }}
            />
            <StreakCalendar
              startDate={challengeData.challenge.start_date}
              completedDates={challengeData.completedDates}
              onDayClick={setSelectedDay}
              selectedDay={selectedDay}
            />
            <AttentionScoreGrid
              startDate={challengeData.challenge.start_date}
              attentionScores={challengeData.attentionScores}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    user && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar>
              {isMobile && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setDrawerOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, fontWeight: 700 }}
              >
                Ekrasworks
              </Typography>
              {/* <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                {user.name?.[0].toUpperCase()}
              </Avatar> */}
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  {user.name?.[0].toUpperCase()}
                </Avatar>
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <List>
              {tabs.map((tab, index) => (
                <ListItem
                  button
                  key={tab.label}
                  onClick={() => {
                    setCurrentTab(index);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>{tab.icon}</ListItemIcon>
                  <ListItemText primary={tab.label} />
                </ListItem>
              ))}
            </List>
          </Drawer>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="h4" gutterBottom fontWeight={700}>
              Welcome back, {user.name.split(" ")[0]}!
            </Typography>

            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              centered
              sx={{
                mb: 3,
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  justifyContent: "center",
                },
                "& .MuiTabs-scroller": {
                  display: "flex",
                  justifyContent: "center",
                },
                "& .MuiTab-root": {
                  fontSize: "18px",
                },
                "& .MuiTab-iconWrapper": {
                  fontSize: "18px",
                },
              }}
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons={isMobile ? "auto" : false}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.label}
                  icon={tab.icon}
                  label={!isMobile && tab.label}
                  iconPosition="start"
                />
              ))}
            </Tabs>

            <MotionCard
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              sx={{
                mt: 4,
                mx: "auto",
                maxWidth: 800,
                bgcolor: "background.paper",
              }}
            >
              <CardContent>{renderContent()}</CardContent>
            </MotionCard>
          </Box>

          <Snackbar
            open={showShareSnackbar}
            autoHideDuration={3000}
            onClose={() => setShowShareSnackbar(false)}
            message="Share link copied to clipboard!"
          />
        </Container>
      </ThemeProvider>
    )
  );
};

export default Main;
