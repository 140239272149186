import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Box,
  IconButton,
  LinearProgress,
  Button,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/material/styles";
import { questions } from "../utils/questions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PhoneBezel = styled(Box)(({ theme }) => ({
  width: 320, // Slightly wider
  height: 650, // Taller to accommodate content
  margin: "auto",
  borderRadius: 45,
  background: "#1a1a1a",
  padding: "12px",
  position: "relative",
  boxShadow:
    "0 20px 40px rgba(0,0,0,0.3), inset 0 2px 10px rgba(255,255,255,0.1)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "30%",
    height: "25px",
    backgroundColor: "#000",
    borderBottomLeftRadius: "14px",
    borderBottomRightRadius: "14px",
    zIndex: 1000,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "8px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "40%",
    height: "4px",
    backgroundColor: "#333",
    borderRadius: "4px",
  },
}));

const ChatContainer = styled(Box)({
  background: "#E5DDD5",
  height: "100%",
  padding: "16px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  scrollBehavior: "smooth", // Add smooth scrolling
});

const PhoneFrame = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 35,
  overflow: "hidden",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

const PhoneHeader = styled(Box)(({ theme }) => ({
  background: "#075E54",
  color: "white",
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  background: isUser ? "#DCF8C6" : "#FFFFFF",
  padding: theme.spacing(1.5),
  borderRadius: 10,
  maxWidth: "80%",
  marginBottom: theme.spacing(1),
  alignSelf: isUser ? "flex-end" : "flex-start",
  boxShadow: "0 1px 0.5px rgba(0,0,0,0.13)",
}));

const SurveyModal = ({ open, onClose }) => {
  const [messages, setMessages] = useState([
    {
      id: Date.now(),
      text: questions[Object.keys(questions)[0]].text,
      isUser: false,
      options: questions[Object.keys(questions)[0]].options,
    },
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentInput, setCurrentInput] = useState("");
  const [results, setResults] = useState(null);

  const progress = (currentQuestion / Object.keys(questions).length) * 100;

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, results]); // Scroll when messages or results change

  const formatAnswerDisplay = (value, isIncome) => {
    if (isIncome) return value.toString();

    const questionKey = Object.keys(questions)[currentQuestion];
    const question = questions[questionKey];

    if (Array.isArray(value)) {
      return (
        question.options.find(
          (opt) => JSON.stringify(opt.value) === JSON.stringify(value)
        )?.label || value[0]
      );
    }

    return (
      question.options.find((opt) => opt.value === value)?.label ||
      value.toString()
    );
  };

  const calculateResults = (currentAnswers) => {
    try {
      setLoading(true);

      const workdays = 20;
      const productivityBoostFactor = 1.5;

      // Current metrics
      const currentWorkHours = currentAnswers.workday * workdays;
      const currentProductiveHours = currentAnswers.focus * workdays;

      // Improved productivity calculations
      const newRequiredProductiveHours =
        currentProductiveHours / productivityBoostFactor;
      const totalFreedTime =
        currentProductiveHours - newRequiredProductiveHours;

      // Split freed time between productivity and leisure
      const extraProductiveHours = totalFreedTime / 2;
      const leisureGained = totalFreedTime / 2;

      // Value calculations
      const incomeValue = currentAnswers.income || currentAnswers.currency[2]; // Use default if no input
      const valuePerHour = incomeValue / currentProductiveHours;
      const additionalProductivityValue = extraProductiveHours * valuePerHour;

      const results = {
        currentClock: currentWorkHours,
        currentProductive: currentProductiveHours,
        newRequiredHours: newRequiredProductiveHours,
        totalFreedTime: totalFreedTime,
        productiveGained: extraProductiveHours,
        leisureGained: leisureGained,
        monthlyIncome: incomeValue,
        valuePerHour: valuePerHour,
        additionalValue: additionalProductivityValue,
        currencySymbol: currentAnswers.currency[1],
        challengeSolution: currentAnswers.challenge[1],
        annualLeisureGained: leisureGained * 12,
      };

      setResults(results);
      setLoading(false);
    } catch (err) {
      console.error(
        "Calculation error:",
        err,
        "Current answers:",
        currentAnswers
      );
      setError("Error calculating results. Please try again.");
      setLoading(false);
    }
  };

  const formatMessage = () => {
    if (!results) return "";

    const leisureHours = Math.round(results.leisureGained);
    let leisureMessage = "";

    if (leisureHours >= 24) {
      leisureMessage = `${leisureHours} extra leisure hours every month - that's like having several extra days off!`;
    } else if (leisureHours >= 16) {
      leisureMessage = `${leisureHours} extra leisure hours every month - that's like having two full free days!`;
    } else if (leisureHours >= 8) {
      leisureMessage = `${leisureHours} extra leisure hours every month - that's like having an extra free day!`;
    } else {
      leisureMessage = `${leisureHours} extra leisure hours every month - that's meaningful time back in your day!`;
    }

    return `I see you work ${Math.round(
      results.currentClock
    )} hours monthly, with ${Math.round(
      results.currentProductive
    )} truly productive hours. Each productive hour generates ${
      results.currencySymbol
    }${Math.round(results.valuePerHour)} in value.\n\nThrough ${
      results.challengeSolution
    }, you can free up ${Math.round(
      results.totalFreedTime
    )} hours monthly. By reinvesting half of this time, you'll gain ${Math.round(
      results.productiveGained
    )} extra productive hours, worth an additional ${
      results.currencySymbol
    }${Math.round(
      results.additionalValue
    )}.\n\nThe best part? You'll get ${leisureMessage}\n\nFocus & Finish helps you work smarter, not longer. Ready to transform your workday?`;
  };

  const handleAnswer = async (value, isIncome = false) => {
    try {
      setLoading(true);
      const questionKey = Object.keys(questions)[currentQuestion];

      // Store the answer in answers state
      const newAnswers = {
        ...answers,
        [questionKey]: value,
      };
      setAnswers(newAnswers);

      // Add user's answer as a message
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          text: formatAnswerDisplay(value, isIncome),
          isUser: true,
        },
      ]);

      await new Promise((resolve) => setTimeout(resolve, 800));

      if (currentQuestion < Object.keys(questions).length - 1) {
        const nextQuestionKey = Object.keys(questions)[currentQuestion + 1];
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            text: questions[nextQuestionKey].text,
            isUser: false,
            options: questions[nextQuestionKey].options,
          },
        ]);
        setCurrentQuestion((prev) => prev + 1);
      } else {
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            text: "Calculating your results...",
            isUser: false,
          },
        ]);

        // Add 2 second delay before showing results
        await new Promise((resolve) => setTimeout(resolve, 2000));
        calculateResults(newAnswers);
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperComponent={Box} // Change to Box to remove Paper styles
      sx={{
        "& .MuiDialog-paper": {
          background: "transparent",
          boxShadow: "none",
          margin: "16px",
        },
      }}
    >
      <PhoneBezel>
        <PhoneFrame>
          <PhoneHeader>
            <WhatsAppIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Ekrasworks</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton size="small" onClick={onClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </PhoneHeader>

          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 2 }}
          />

          <ChatContainer ref={chatContainerRef}>
            {messages.map((message, index) => {
              console.log("message: ", message);
              return (
                <MessageBubble key={message.id} isUser={message.isUser}>
                  <Typography variant="body1">
                    {message.text === "What's your monthly income?"
                      ? message.text + " in (" + answers.currency + ")"
                      : message.text}
                  </Typography>

                  {!message.isUser && message.options && (
                    <RadioGroup>
                      {message.options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={JSON.stringify(option.value)}
                          control={<Radio />}
                          label={option.label}
                          disabled={loading}
                          onChange={(e) =>
                            handleAnswer(JSON.parse(e.target.value))
                          }
                        />
                      ))}
                    </RadioGroup>
                  )}

                  {!message.isUser &&
                    index === messages.length - 1 &&
                    Object.keys(questions)[currentQuestion] === "income" && (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder="Use default"
                          variant="outlined"
                          value={currentInput}
                          onChange={(e) => setCurrentInput(e.target.value)}
                          disabled={loading}
                          sx={{ backgroundColor: "#fff" }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => {
                            // Get the selected currency's default value from answers
                            const selectedCurrency = answers.currency;
                            const defaultValue = selectedCurrency
                              ? selectedCurrency[2]
                              : 5000;
                            handleAnswer(
                              currentInput
                                ? parseFloat(currentInput)
                                : defaultValue,
                              true
                            );
                          }}
                        >
                          Send
                        </Button>
                      </Box>
                    )}
                </MessageBubble>
              );
            })}

            {results && (
              <>
                <MessageBubble>
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: "pre-line" }} // Add this style
                  >
                    {formatMessage()}
                  </Typography>
                </MessageBubble>

                <MessageBubble isUser>
                  <Box display="flex" alignItems="center">
                    <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
                    <Typography>Thanks for completing the survey!</Typography>
                  </Box>
                </MessageBubble>
              </>
            )}
          </ChatContainer>
        </PhoneFrame>
      </PhoneBezel>
    </Dialog>
  );
};

export default SurveyModal;
