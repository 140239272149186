import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const NotificationTimePicker = ({ userId, supabase }) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [userTimezone, setUserTimezone] = useState("UTC");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data, error } = await supabase
        .from("users_onboard")
        .select("preferred_notification_time, timezone")
        .eq("id", userId)
        .single();

      if (error) throw error;

      if (data) {
        setUserTimezone(data.timezone || "UTC");
        if (data.preferred_notification_time) {
          const localTime = dayjs(data.preferred_notification_time).tz(
            data.timezone || "UTC"
          );
          setSelectedTime(localTime);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTimeChange = async (newTime) => {
    setSelectedTime(newTime);
    setSaving(true);
    try {
      const utcTime = newTime.tz(userTimezone).format();

      await supabase
        .from("users_onboard")
        .update({
          preferred_notification_time: utcTime,
        })
        .eq("id", userId);
    } catch (error) {
      console.error("Error saving time:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {loading ? (
        <CircularProgress size={18} />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={selectedTime}
            onChange={handleTimeChange}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Typography
                ref={inputRef}
                {...inputProps}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  color: "text.primary",
                  fontWeight: 500,
                  "&:hover": { color: "primary.main" },
                }}
              >
                {selectedTime ? selectedTime.format("hh:mm A") : "Set Time"}
                {saving && <CircularProgress size={14} />}
              </Typography>
            )}
          />
        </LocalizationProvider>
      )}
    </Box>
  );
};

export default NotificationTimePicker;
