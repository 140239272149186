import { supabase } from "../utils/supabase";

export const createUser = async (name, email) => {
  const { data, error } = await supabase
    .from("users_onboard")
    .insert([
      {
        name: name,
        email: email,
      },
    ])
    .select();

  if (error) {
    console.error("Error inserting data:", error);
    return { error };
  }

  return { data };
};

export const getUserByPhone = async (phone) => {
  try {
    const { data, error } = await supabase
      .from("users_onboard")
      .select("*")
      .eq("whatsapp_phone_no", phone)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching User by Phone:", error.message);
    return null;
  }
};
