import React, { useState, useEffect } from "react";
import { supabase } from "../utils/supabase";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Alert,
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import dayjs from "dayjs";

const DiscountCodeForm = () => {
  const [formData, setFormData] = useState({
    code: "",
    discount_type: "percentage",
    discount_value: "",
    expiration_date: dayjs().add(1, "month"),
  });
  const [loading, setLoading] = useState(false);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [editMode, setEditMode] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [deactivateConfirmOpen, setDeactivateConfirmOpen] = useState(false);

  useEffect(() => {
    fetchDiscountCodes();
  }, []);

  const fetchDiscountCodes = async () => {
    const { data, error } = await supabase
      .from("discount_codes")
      .select("*")
      .order("created_at", { ascending: false });

    if (data) setDiscountCodes(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase.from("discount_codes").insert([
        {
          code: formData.code.toUpperCase(),
          discount_type: formData.discount_type,
          discount_value: parseFloat(formData.discount_value),
          expiration_date: formData.expiration_date.toISOString(),
          is_active: true,
        },
      ]);

      if (error) throw error;

      setAlert({
        open: true,
        message: "Discount code created successfully!",
        severity: "success",
      });

      resetForm();
      fetchDiscountCodes();
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const { error } = await supabase
        .from("discount_codes")
        .update({
          discount_type: formData.discount_type,
          discount_value: parseFloat(formData.discount_value),
          expiration_date: formData.expiration_date.toISOString(),
        })
        .eq("id", selectedCode.id);

      if (error) throw error;

      setAlert({
        open: true,
        message: "Discount code updated successfully!",
        severity: "success",
      });

      resetForm();
      fetchDiscountCodes();
      setEditMode(false);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivate = async () => {
    try {
      const { error } = await supabase
        .from("discount_codes")
        .update({ is_active: false })
        .eq("id", selectedCode.id);

      if (error) throw error;

      setAlert({
        open: true,
        message: "Discount code deactivated successfully!",
        severity: "success",
      });

      fetchDiscountCodes();
      setDeactivateConfirmOpen(false);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const resetForm = () => {
    setFormData({
      code: "",
      discount_type: "percentage",
      discount_value: "",
      expiration_date: dayjs().add(1, "month"),
    });
    setSelectedCode(null);
  };

  const columns = [
    { field: "code", headerName: "Code", width: 130 },
    {
      field: "discount_type",
      headerName: "Type",
      width: 130,
      renderCell: (params) =>
        params.value.charAt(0).toUpperCase() + params.value.slice(1),
    },
    {
      field: "discount_value",
      headerName: "Value",
      width: 130,
      renderCell: (params) =>
        `${params.value}${
          params.row.discount_type === "percentage" ? "%" : " USD"
        }`,
    },
    {
      field: "expiration_date",
      headerName: "Expires",
      width: 200,
      renderCell: (params) => dayjs(params.value).format("MMM D, YYYY h:mm A"),
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 130,
      renderCell: (params) => (
        <Typography color={params.value ? "success.main" : "error.main"}>
          {params.value ? "Active" : "Inactive"}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <Box>
          {params.row.is_active && (
            <>
              <IconButton
                onClick={() => {
                  setSelectedCode(params.row);
                  setFormData({
                    ...params.row,
                    expiration_date: dayjs(params.row.expiration_date),
                  });
                  setEditMode(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedCode(params.row);
                  setDeactivateConfirmOpen(true);
                }}
                color="error"
              >
                <BlockIcon />
              </IconButton>
            </>
          )}
        </Box>
      ),
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 2 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            {editMode ? "Edit Discount Code" : "Create Discount Code"}
          </Typography>

          <Box component="form" onSubmit={editMode ? handleEdit : handleSubmit}>
            <TextField
              fullWidth
              label="Discount Code"
              value={formData.code}
              onChange={(e) =>
                setFormData({ ...formData, code: e.target.value })
              }
              margin="normal"
              required
              disabled={editMode}
              inputProps={{ style: { textTransform: "uppercase" } }}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Discount Type</InputLabel>
              <Select
                value={formData.discount_type}
                onChange={(e) =>
                  setFormData({ ...formData, discount_type: e.target.value })
                }
                label="Discount Type"
                required
              >
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="fixed">Fixed Amount</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label={
                formData.discount_type === "percentage"
                  ? "Discount Percentage"
                  : "Discount Amount"
              }
              type="number"
              value={formData.discount_value}
              onChange={(e) =>
                setFormData({ ...formData, discount_value: e.target.value })
              }
              margin="normal"
              required
              InputProps={{
                inputProps: {
                  min: 0,
                  max: formData.discount_type === "percentage" ? 100 : 999999,
                  step: "0.01",
                },
              }}
            />

            <DateTimePicker
              label="Expiration Date"
              value={formData.expiration_date}
              onChange={(newValue) =>
                setFormData({ ...formData, expiration_date: newValue })
              }
              minDateTime={dayjs()}
              sx={{ mt: 2, width: "100%" }}
            />

            <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
              >
                {loading ? "Processing..." : editMode ? "Update" : "Create"}
              </Button>
              {editMode && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setEditMode(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Box>
        </Paper>

        <Typography variant="h5" gutterBottom>
          Existing Discount Codes
        </Typography>

        <DataGrid
          rows={discountCodes}
          columns={columns}
          pageSize={5}
          autoHeight
          disableSelectionOnClick
          sx={{
            backgroundColor: "background.paper",
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        />

        {/* Deactivate Confirmation Dialog */}
        <Dialog
          open={deactivateConfirmOpen}
          onClose={() => setDeactivateConfirmOpen(false)}
        >
          <DialogTitle>Confirm Deactivation</DialogTitle>
          <DialogContent>
            Are you sure you want to deactivate this discount code?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeactivateConfirmOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleDeactivate} color="error">
              Deactivate
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          <Alert
            severity={alert.severity}
            onClose={() => setAlert({ ...alert, open: false })}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default DiscountCodeForm;
