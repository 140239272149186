import React, {
  useEffect,
  memo,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTimer } from "react-timer-hook";
import { PlayArrow } from "@mui/icons-material";
import { Button, Box, Grid } from "@mui/material";
import { NotificationsActive } from "@mui/icons-material";

const MyTimer = memo(
  function MyTimer({ bellTimes, bellAudio, setShowVideo, videoRef, user }) {
    const timerRef = useRef({
      bellAudio,
      bellTimes,
    });

    const [expiryTimestamp] = useState(new Date().getTime() + 1800 * 1000);
    const [showAttentionCheck, setShowAttentionCheck] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const timerConfig = useMemo(
      () => ({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => setShowVideo(true),
      }),
      [expiryTimestamp, setShowVideo]
    );

    const { seconds, minutes, isRunning, start, totalSeconds } =
      useTimer(timerConfig);

    // Memoized formatted time values
    const formattedTime = useMemo(
      () => ({
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      }),
      [minutes, seconds]
    );

    const handleAttentionCheck = useCallback(() => {
      setShowAttentionCheck(true);

      const newTimeoutId = setTimeout(() => {
        let myArray = JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        );
        myArray.push({ isAttentive: false, bellNumber: myArray.length + 1 });
        localStorage.setItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0],
          JSON.stringify(myArray)
        );
        setShowAttentionCheck(false);
      }, 5000);

      setTimeoutId(newTimeoutId);
    }, [user.preferences_link]);

    const handleAttentionResponse = useCallback(
      (isAttentive) => {
        // Clear the timeout to prevent the automatic false response
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }

        let myArray = JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        );
        myArray.push({ isAttentive, bellNumber: myArray.length + 1 });
        localStorage.setItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0],
          JSON.stringify(myArray)
        );
        setShowAttentionCheck(false);
      },
      [timeoutId, user.preferences_link]
    );

    useEffect(() => {
      let myArray =
        JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        ) || [];

      localStorage.setItem(
        "attentionResponses-" + Object.keys(user.preferences_link)[0],
        JSON.stringify(myArray)
      );
    }, [user.preferences_link]);

    // Optimized effect for bell checking
    useEffect(() => {
      const elapsedTime = 1800 - totalSeconds;
      const shouldRingBell = timerRef.current.bellTimes.includes(elapsedTime);
      const responses = JSON.parse(
        localStorage.getItem(
          "attentionResponses-" + Object.keys(user.preferences_link)[0]
        )
      );

      if (shouldRingBell && responses.length < bellTimes.length) {
        timerRef.current.bellAudio.play();
        handleAttentionCheck();
      }
    }, [
      totalSeconds,
      handleAttentionCheck,
      user.preferences_link,
      bellTimes.length,
    ]);

    // Update ref values
    useEffect(() => {
      timerRef.current = {
        bellAudio,
        bellTimes,
      };
    }, [bellAudio, bellTimes]);

    // Handle start
    const handleStart = useCallback(() => {
      if (videoRef.current) {
        videoRef.current.contentWindow.postMessage({ method: "play" }, "*");
      }
      start();
    }, [start]);

    const AttentionBells = () => {
      const responses =
        JSON.parse(
          localStorage.getItem(
            "attentionResponses-" + Object.keys(user.preferences_link)[0]
          )
        ) || [];

      // Create array of 5 bells with their states
      const bells = Array(5)
        .fill()
        .map((_, index) => {
          const response = responses[index];
          return {
            number: index + 1,
            status: response
              ? response.isAttentive
                ? "attentive"
                : "not-attentive"
              : "pending",
          };
        });

      return (
        <Box sx={styles.attentivenessIndicator}>
          {bells.map((bell, index) => (
            <Box
              key={index}
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor:
                  bell.status === "attentive"
                    ? "#4caf50" // Green for attentive
                    : bell.status === "not-attentive"
                    ? "#f44336" // Red for not attentive
                    : "#e0e0e0", // Grey for pending
                border: "2px solid",
                borderColor:
                  bell.status === "attentive"
                    ? "#388e3c" // Darker green border
                    : bell.status === "not-attentive"
                    ? "#d32f2f" // Darker red border
                    : "#bdbdbd", // Grey border for pending
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                transition: "all 0.3s ease",
                boxShadow:
                  bell.status !== "pending"
                    ? "0 2px 4px rgba(0,0,0,0.2)"
                    : "none",
                "&::after":
                  bell.status !== "pending"
                    ? {
                        content: '""',
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        opacity: 0.8,
                      }
                    : {},
                animation:
                  bell.status !== "pending" ? "pulse 2s infinite" : "none",
              }}
            />
          ))}
        </Box>
      );
    };

    return (
      <Box sx={styles.mainContainer}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={styles.container}>
              <div style={styles.timerContainer}>
                <div style={styles.timer}>
                  <span>{formattedTime.minutes}</span>:
                  <span>{formattedTime.seconds}</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "75px",
            }}
          >
            {!isRunning ? (
              <Box sx={styles.startButtonContainer}>
                <Button
                  variant="contained"
                  onClick={handleStart}
                  sx={styles.startButton}
                  startIcon={<PlayArrow sx={{ fontSize: "1.5rem" }} />}
                >
                  Start Timer
                </Button>
              </Box>
            ) : showAttentionCheck ? (
              <Box sx={styles.attentionCheckContainer}>
                <Box sx={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    onClick={() => handleAttentionResponse(true)}
                    sx={styles.responseButton}
                    startIcon={<NotificationsActive />}
                  >
                    Click to Confirm Attention
                  </Button>
                </Box>
              </Box>
            ) : (
              <AttentionBells />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.bellTimes === nextProps.bellTimes &&
      prevProps.bellAudio === nextProps.bellAudio &&
      prevProps.setShowVideo === nextProps.setShowVideo &&
      prevProps.videoRef === nextProps.videoRef &&
      prevProps.user === nextProps.user
    );
  }
);

const styles = {
  container: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Courier New', monospace",
    color: "#000",
  },
  timerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "75px",
  },
  icon: {
    fontSize: "40px",
    color: "#000",
    cursor: "pointer",
    transition: "color 0.3s ease",
  },
  attentionCheckContainer: {
    height: "75px",
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 3,
    borderRadius: 2,
    backgroundColor: "#f8f8f8",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    animation: "fadeIn 0.3s ease-in",
    margin: "0 auto",
  },
  attentionIcon: {
    fontSize: 40,
    marginBottom: 1,
  },
  questionText: {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: "#333",
    textAlign: "center",
    marginBottom: 2,
  },
  buttonContainer: {
    display: "flex",
    maxHeight: "75px",
    justifyContent: "center",
    width: "100%",
  },
  responseButton: {
    padding: "12px 24px",
    fontSize: "1.1rem",
    textTransform: "none",
    backgroundColor: "#2196f3",
    color: "white",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#1976d2",
      transform: "scale(1.02)",
      boxShadow: "0 6px 15px rgba(33,150,243,0.3)",
    },
  },
  startButtonContainer: {
    height: "75px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  startButton: {
    height: "55px",
    padding: "10px 35px",
    fontSize: "1.3rem",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "28px",
    background: "linear-gradient(45deg, #ff3d3d 30%, #ff5252 90%)",
    color: "white",
    boxShadow: "0 4px 10px rgba(255, 61, 61, 0.3)",
    transition: "all 0.3s ease",
    animation: "pulseAndScale 2s infinite",
    "&:hover": {
      background: "linear-gradient(45deg, #e62e2e 30%, #ff3d3d 90%)",
      boxShadow: "0 6px 15px rgba(255, 61, 61, 0.4)",
    },
    "@keyframes pulseAndScale": {
      "0%": {
        transform: "scale(1)",
        boxShadow: "0 4px 10px rgba(255, 61, 61, 0.3)",
      },
      "50%": {
        transform: "scale(1.05)",
        boxShadow: "0 8px 20px rgba(255, 61, 61, 0.5)",
      },
      "100%": {
        transform: "scale(1)",
        boxShadow: "0 4px 10px rgba(255, 61, 61, 0.3)",
      },
    },
  },
  attentivenessIndicator: {
    height: "120px",
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "10px",
    minWidth: "200px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },

  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.05)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  mainContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  timer: {
    fontSize: "70px",
    fontWeight: "bold",
    color: "#000",
    letterSpacing: "1px",
    lineHeight: 1,
    textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
};

export default MyTimer;
