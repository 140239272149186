import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  Divider,
  Grid,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EastIcon from "@mui/icons-material/East";
import { supabase } from "../utils/supabase";
import S3PDFViewer from "../components/S3PDFViewer";

const DayZero = ({ challengeData, user, docKey, videoLink, day }) => {
  const theme = useTheme();

  const handleStartChallenge = async (e) => {
    e.preventDefault();
    console.log("user: ", user);

    try {
      // Calculate preferred notification time (23 hours from now in UTC)
      const preferredTime = new Date(
        Date.now() + 23 * 60 * 60 * 1000
      ).toISOString();

      // Run all database operations
      await Promise.all([
        // 1. Update the challenges table
        supabase
          .from("challenges")
          .update({ hasStarted: true })
          .eq("id", challengeData.id),

        // 2. Update the users_onboard table with preferred notification time
        supabase
          .from("users_onboard")
          .update({ preferred_notification_time: preferredTime })
          .eq("id", user.id),
      ]);

      // 3. Get the current URL and update the 'day' parameter to 1
      const url = new URL(window.location.href);
      url.searchParams.set("day", "1");

      // Navigate to the new URL
      window.location.href = url.toString();
    } catch (error) {
      console.error("Error starting challenge:", error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box sx={{ mb: 8 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 700,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            backgroundClip: "text",
            textFillColor: "transparent",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Transform Your Journey
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
          A 21-Day Challenge to Redefine Your Potential
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ alignItems: "stretch" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                mb: 3,
                color: "#1a1a1a",
              }}
            >
              Program Overview
            </Typography>
            <Box
              sx={{
                flex: 1,
                aspectRatio: "16/9",
                overflow: "hidden",
                borderRadius: 1,
                border: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {/* <PDFViewer pdfFile={PDFDay1} /> */}
              <S3PDFViewer docKey={docKey} user={user} day={day} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                mb: 3,
                color: "#1a1a1a",
              }}
            >
              Introduction Video
            </Typography>
            <Box
              sx={{
                flex: 1,
                aspectRatio: "16/9",
                position: "relative",
                borderRadius: 1,
                overflow: "hidden",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                "& iframe": {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                },
              }}
            >
              <iframe
                src={videoLink}
                title="Vimeo video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Box
        sx={{
          display: "flex",
          gap: 3,
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={handleStartChallenge}
          endIcon={<EastIcon />}
          sx={{
            py: 2,
            px: 4,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1.1rem",
          }}
        >
          Begin 21-Day Challenge
        </Button>

        <Button
          variant="outlined"
          size="large"
          href="https://api.whatsapp.com/send/?phone=19259998038&text=Start%20Assessment"
          target="_blank"
          startIcon={<AssessmentIcon />}
          sx={{
            py: 2,
            px: 4,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1.1rem",
          }}
        >
          Take Assessment First
        </Button>
      </Box>

      <Typography
        variant="body1"
        color="text.secondary"
        align="center"
        sx={{ mt: 2 }}
      >
        Embark on a transformative journey designed to elevate your potential
      </Typography>
    </Container>
  );
};

export default DayZero;
