import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import the layouts
import Home from "./layouts/Home";
import Stripe from "./layouts/Stripe";
import Return from "./layouts/Return";
import CourseList from "./layouts/CourseList";
import UserRegistrationPage from "./layouts/UserRegistrationPage";
import ShareableView from "./layouts/ShareableView";
import Gist from "./layouts/Gist";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/return" element={<Return />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/register" element={<UserRegistrationPage />} />
        <Route path="/share/:shareId" element={<ShareableView />} />
        <Route path="/gist" element={<Gist />} />
      </Routes>
    </Router>
  );
};

export default App;
